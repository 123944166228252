import { Component, OnInit, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { getreport, getskill, getallassscore, getSubSkillScore, getcert } from '../services/report/report';
import { ReportService } from '../services/report/report.service';
import { ThemeService } from '../services/profile/theme.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { IpService } from '../services/ip/ip.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatasharingServiceService } from "../services/ip/datasharing-service.service";
import { url } from ".././services/baseurl";
import { profileget } from '../services/profile/profile';
import { ProfileService } from '../services/profile/profile.service';
import html2canvas from 'html2canvas';
import { ToastrService } from 'ngx-toastr';

//////////////////Chart
import { ChartDataSets } from 'chart.js';
import { Color } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { LocaldatastorageService } from '../localdatastorage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-roadmap',
  templateUrl: './roadmap.component.html',
  styleUrls: ['./roadmap.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  },
  // encapsulation: ViewEncapsulation.None
})
export class RoadmapComponent implements OnInit {
  public barChartLabels = ['Memory', 'VisualProcessing', 'Focus&Attention', 'ProblemSolving', 'Linguistics'];
  url = new url();
  //////theme variables//////
  colortheme = ['black', 'white'];
  colortheme_shad_val;
  colortheme_bg;
  colortheme_txt;
  colortheme_base = ['#0D0F12', 'white']
  colortheme_bg_base;
  colortheme_base_sec = ['#181C20', '#F0F0F0']
  colortheme_bg_base_sec;
  colortheme_txt1_Arr = ['rgba(255, 255, 255, 0.65)', 'black'];
  colortheme_txt1;

  color1; color2; color3; color4;
  load1;
  //////report variables//////
  color_val = [false, false, false, false, false, false, false, false];
  after_post = false;
  getallassscore = new getallassscore();
  isfull_val = true;
  getcert = new getcert();
  colorVal: any;
  element: any;
  themechange: any;
  public show: boolean = false;
  public buttonName: any = 'Show';
  getReport = new getreport();
  isReportGet: boolean;
  showBtn = -1;
  response: any;
  blob_file;
  lightThemeIdx: any = 1;
  assesment_types: any = [];
  crt_url = this.url.dlcertificateUrl + localStorage.getItem("uid") + ".png";
  img_src = false;
  tooltipData: any;
  assesmentDetail: any;
  avgScore: any;
  blink: any;
  hidePt2:boolean=false;
  highlight: number;
  isDark: boolean;
  assesData: number;
  assesmenName: number = 0;
  dwnload_url:boolean=false;
  constructor(
    private translate:TranslateService,
    private dataSharingService: DatasharingServiceService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private _snackBar: MatSnackBar,

    private LocaldatastorageService_ts: LocaldatastorageService,
    private datePipe: DatePipe,
    private ip: IpService,
    private theme: ThemeService,
    private ngxService: NgxUiLoaderService,
    private reportService: ReportService, private ProfileService_ts: ProfileService, private router: Router) {
      if(localStorage.getItem('hidept2')=='1'){
        this.hidePt2=true
      }
      else{
        this.hidePt2=false
      }
      let browserLang
    // debugger
      // if(localStorage.getItem('lang')){
      // browserLang=localStorage.getItem('lang')
      // }
      /////////////////////////////////////////////////////////////
      if(localStorage.getItem('selectedlanguage')){
        browserLang=localStorage.getItem('selectedlanguage')
        if(browserLang=='4'){
          browserLang='ar'
        }
        else if(browserLang=='1'){
          browserLang='en'
        }
      }
      else{
      browserLang=translate.getBrowserLang()
      }
   
      
      translate.use(browserLang.match(/en/) ? browserLang : 'en');
  
      if(browserLang=='ar'){
    //  var element = document.getElementsByTagName("body")[0]
     // element.classList.add("rtl");
      }
    this.isDark = parseInt(localStorage.getItem("isdark")) == 0 ? true : false;

    this.dataSharingService.callscore_fn.subscribe(value => {
      if (value != -1) {
        var val_con = value;
        this.dataSharingService.callscore_fn.next(-1);
        //  this.scoreFn(val_con);
      }
    });

    this.dataSharingService.backtoroad.subscribe(value => {
      if (value == 1) {
        this.backFn();
        this.dataSharingService.backtoroad.next(0);

      }
    });
    this.lightThemeIdx = parseInt(localStorage.getItem("isdark"))
  }
  @ViewChild('newinfo', { static: false }) newinfo;
  @ViewChild('certificate', { static: false }) certificate;
  conditional = [true,
    true, true, true, true, true, true, true, true,
    true, true, true, true, true, true, true, true,
    true, true, true, true, true, true, true, true,
    true];
  i; id: any;
  mrgin_Arr = [['1.5vw', '1.2vw', '.5vw', '3.5vw'], ['-4vw', '-5vw', '-6vw', '9vw']];
  val_val = 0;

  // Assesment_names_arr = ["Initial Assessment", "Detailed Assessment", "Personalized Training- 1",
  //   "Personalized Training- 2", 'Post Assessment'];
  
    Assesment_names_arr=this.translate.instant('progress-page.assessment')
  Assesment_names_val = '';
  Cycle_val = false;
  argn_val = 0;
  cycle_score_bspt_Arr = [];
  score_val_Arr = [];
  isRoadMap: boolean = true;
  skillkitRegular_btn: boolean = false;
  isSkilkit: boolean = false;

  snackMe(e) { this._snackBar.open(e, 'OK', { duration: 5000, }); }

  scoreFn(assname, argn) {
//debugger
    // this.blink = this.assesmentDetail.sessionstatus == 'completed' ?
    //   this.assesmentDetail.assessment != 'Post' && this.assesmentDetail.assessment != 'Initial' ?
    //     Number(this.assesmentDetail.assessment) :
    //     this.assesmentDetail.assessment == 'Post' ? 25 :
    //       0 : this.assesmentDetail.assessment != 'Post' && this.assesmentDetail.assessment != 'Initial' ?
    //     Number(this.assesmentDetail.assessment) - 1 :
    //     this.assesmentDetail.assessment == 'Post' ? 24 : -1

    var asses = this.assesmentDetail.assessment == 'Initial' ? 0 : this.assesmentDetail.assessment == 'Post' ? 25 : this.assesmentDetail.assessment
    console.log(asses, this.assesmentDetail.assessment)
    // this.highlight = this.assesmentDetail.assessment != 'Post' && this.assesmentDetail.assessment != 'Initial' ?
    //   Number(this.assesmentDetail.assessment) : this.assesmentDetail.assessment == 'Post' ? 25 : 0
    if (assname != 1) {
      if (this.conditional[argn] == false) {
        this.subsco(assname, argn);
      }
      else {
        if (Number(asses) + 1 == argn && this.assesmentDetail.nextsession && this.assesmentDetail.sessionstatus == 'not completed') {
          // this.snackMe('Your next assesment on ' + "\n" + this.assesmentDetail.nextsession)
          this.dwnload_url=false;
          this.toastr.warning('Your next session is on ' + "\n" + this.assesmentDetail.nextsession)
        }
        else if (Number(asses) == argn - 1 && this.assesmentDetail.nextsession && this.assesmentDetail.sessionstatus == 'completed') {
          // this.snackMe('Your next assesment on ' + "\n" + this.assesmentDetail.nextsession)
          this.dwnload_url=true;
          this.toastr.warning('Your next session is on ' + "\n" + this.assesmentDetail.nextsession)

        }
        else if (this.highlight == argn) {
          // this.snackMe('Please complete the current cycle at least once.')
          this.toastr.warning('Please complete the current cycle at least once.')

        }

        else {
          // this.snackMe('Please complete all the puzzles in the previous session.')
          this.toastr.warning('Please complete all the puzzles in the previous session.')

        }
      }
    }
    else if (assname == 1) {
      if (argn == 0) {
        //console.log('argo',this.conditional[0],'=>',this.assesmentDetail);
        if ((this.conditional[0] == false && this.assesmentDetail.assessment != 'Initial') ||( this.assesmentDetail.sessionstatus == 'completed' && this.assesmentDetail.assessment == 'Initial')) {
          this.subsco(assname, argn);
        }
        else {
          if (Number(asses) == 0 && this.assesmentDetail.nextsession && this.assesmentDetail.sessionstatus == 'completed') {
            // this.snackMe('Your next assesment on ' + "\n" + this.assesmentDetail.nextsession)
            this.dwnload_url=true;
            this.toastr.warning('Your next session is on ' + "\n" + this.assesmentDetail.nextsession)

          }
          else if (this.highlight == 0) {
            // this.snackMe('Please complete the current cycle at least once.')
            this.toastr.warning('Please complete the current cycle at least once.')

          }
          else {
            // this.snackMe('Please complete all the puzzles in the previous session.')
            this.toastr.warning('Please complete all the puzzles in the previous session.')

          }
        }
      }
      else if (argn == 1) {
        if (this.conditional[8] == false) {
          this.subsco(assname, argn);
        }
      }
      else if (argn == 2) {
        if (this.conditional[16] == false) {
          this.subsco(assname, argn);
        }
      }
      else if (argn == 3) {
        if (this.conditional[24] == false) {
          this.subsco(assname, argn);
        }
      }
      else if (argn == 4) {

        if (this.hidePt2==true && this.conditional[17] == false && this.highlight >= 17  && (this.assesmentDetail.sessionstatus == 'completed' && this.assesmentDetail.assessment == 'Post')) {
          this.subsco(assname, argn);
        }
        if (this.conditional[25] == false && this.highlight >= 25  && (this.assesmentDetail.sessionstatus == 'completed' && this.assesmentDetail.assessment == 'Post')) {
          this.subsco(assname, argn);
        }
        else {
          if (Number(asses) + 1 == 25 && this.assesmentDetail.nextsession && this.assesmentDetail.sessionstatus == 'not completed') {
            // this.snackMe('Your next assesment on ' + "\n" + this.assesmentDetail.nextsession)
            this.dwnload_url=false;
            this.toastr.warning('Your next session is on ' + "\n" + this.assesmentDetail.nextsession)

          }
          else if (Number(asses) == 24 && this.assesmentDetail.nextsession && this.assesmentDetail.sessionstatus == 'completed') {
            // this.snackMe('Your next assesment on ' + "\n" + this.assesmentDetail.nextsession)
            this.dwnload_url=true;
            this.toastr.warning('Your next session is on ' + "\n" + this.assesmentDetail.nextsession)

          }
          else if (this.highlight == 25 ) {
            // this.snackMe('Please complete the current cycle at least once.')
            if(!this.hidePt2){
            this.toastr.warning('Please complete the current cycle at least once.')
            }

          }
          else {
            // this.snackMe('Please complete all the puzzles in the previous session.')
            this.toastr.warning('Please complete all the puzzles in the previous session.')

          }
        }
      }
    }


  }
  subsco(assname, argn) {
   // debugger
    localStorage.setItem("roadback", (1).toString());
    this.isRoadMap = false;
    //////function for setting Assement name on roadmap's distinct score popup  - starts//////
    //console.log(assname);
    //console.log(argn);

    this.argn_val = argn;
    this.Cycle_val = false;

    // this.modalService.open(this.newinfo, { centered: true });
    if (argn == -1) {
      //console.log("argn -1");
    } else if (assname != 1) {

      if (argn == 0) {
        this.Assesment_names_val = this.Assesment_names_arr[0];
        this.assesmenName = 0;

      }
      else if (argn > 0 && argn < 9) {
        this.Cycle_val = true;
        this.Assesment_names_val = this.Assesment_names_arr[1] + ' - Cycle - ' + argn;
        this.assesmenName = 1;

      }
      else if (argn > 8 && argn < 17) {
        if (localStorage.getItem("isfullschudle_stored") == '0') {
          this.Cycle_val = false;
          this.Assesment_names_val = this.Assesment_names_arr[4];
          this.assesmenName = 4;

        }
        else {
          this.Cycle_val = true;
          this.Assesment_names_val = this.Assesment_names_arr[2] + ' - Cycle - ' + argn;
          this.assesmenName = 2;

        }
      }
      else if (argn > 16 && argn < 25) {
        this.Cycle_val = true;
        this.Assesment_names_val = this.Assesment_names_arr[3] + ' - Cycle - ' + argn;
        this.assesmenName = 3;

      }
      else {
        this.Assesment_names_val = this.Assesment_names_arr[4];
        this.assesmenName = 4;

      }


      console.log(argn)
      console.log(this.score_val_Arr)
      console.log(argn < this.score_val_Arr.length)
      if (argn < this.score_val_Arr.length) {
        this.changeBarChartVal(assname, argn);
      } else {
        this.barChartData = []
        this.needleValue = 0.0
        var arr = []
        for (let i = 0; i < this.skillVal.length; i++) {
          arr.push(0)
          // this.barChartData.push({ data: [0], label: this.skillVal[i] });
        }

        this.barChartData.push({ data: arr, label: 'score' });
      }
      // cycle_score_bspt_Arr
      // this.getReport()
    } else if (assname == 1) {
     // debugger
      // //console.log('here')
      if (argn == 0) {
        this.Assesment_names_val = this.Assesment_names_arr[0];
        this.assesmenName = 0;

      } else if (argn == 1) {
        this.Assesment_names_val = this.Assesment_names_arr[1];
        this.assesmenName = 1;

      } else if (argn == 2) {
        this.Assesment_names_val = this.Assesment_names_arr[2];
        this.assesmenName = 2;

      } else if (argn == 3) {
        this.Assesment_names_val = this.Assesment_names_arr[3];
        this.assesmenName = 3;

      } else if (argn == 4) {
        this.Assesment_names_val = this.Assesment_names_arr[4];
        this.assesmenName = 4;

      }
      console.log(this.over_all_score_val)
      console.log(argn < this.over_all_score_val.length)
      if (argn < this.over_all_score_val.length || (this.hidePt2==true && argn == this.over_all_score_val.length)) {
        this.changeBarChartVal(assname, argn);
      } else {
        this.barChartData = []
        this.needleValue = 0.0
        // for (let i = 0; i < this.skillVal.length; i++) {
        //   this.barChartData.push({ data: [0], label: this.skillVal[i] });
        // }
        var arr = []
        for (let i = 0; i < this.skillVal.length; i++) {
          arr.push(0)
          // this.barChartData.push({ data: [0], label: this.skillVal[i] });
        }

        this.barChartData.push({ data: arr, label: 'score' });
        this.isSkilkit = true;
        this.SubSkill_chk = false;
        this.isBtnVis = false;
      }
    }
    //////function for setting Assement name on roadmap's distinct score popup  - ends//////
  }

  changeChart(assname) {
    //console.log(this.selectedChart)
    //console.log(assname)
    this.changeBarChartVal(assname, this.selectedChart)
  }

  downloadUrl() {
    //////function for downloading certificate //////
    // this.toDataURL('https://pbs.twimg.com/profile_images/558329813782376448/H2cb-84q_400x400.jpeg',
    this.toDataURL(this.url.serverurl + 'resources1/' + localStorage.getItem("uid") + ".png",
      function (dataUrl) {
        console.log(dataUrl);
        const a: any = document.createElement('a');
        a.href = dataUrl;
        a.download = (localStorage.getItem("uname"))[0].toUpperCase() + (localStorage.getItem("uname")).substr(1).toLowerCase() + ` Certificate.png`;
        document.body.appendChild(a);
        a.style = 'display: none';
        a.click();
        a.remove();
      })
  }

  toDataURL(url_arg, callback) {
    // console.log(url_arg);
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url_arg);
    xhr.responseType = 'blob';
    xhr.send();
  }

  selectedChart: number;
  isBtnVis: boolean = false;
  isinit: boolean = false;
  ispost: boolean = false;
  isdet: boolean = false;
  changeBarChartVal(assname, argn) {
  //  debugger
    this.isSkilkit = true;
    this.SubSkill_chk = false;


    this.selectedChart = argn;
    this.sub_chk = argn;
    // console.log('here')
    console.log(this.selectedChart)
    if (this.selectedChart == 0) {
      if (Number(localStorage.getItem('rep3_needleValue')) != 0) {
        this.isinit = true;
        this.ispost = false;
        this.isdet = false;
      }
      if (Number(localStorage.getItem('rep4_needleValue')) != 0) {
        this.ispost = true;
        this.isinit = false;
        this.isdet = false;
      }
      if (Number(localStorage.getItem('rep5_needleValue')) != 0) {
        this.isdet = true;
        this.ispost = false;
        this.isinit = false;
      }
    }

    if (assname == 1) {
      this.isBtnVis = true;
      if (this.over_all_score_val.length > 0) {

        if (argn <= 1 || argn == 4) {
          this.skillkitRegular_btn = false;
        } else {
          this.skillkitRegular_btn = true;
        }
        if (argn == 4) {
          if (localStorage.getItem("isfullschudle_stored") == '0') {
            argn = 2
          } else {
            argn = 4;
          }
        }
        if (argn < this.over_all_score_val.length || (this.hidePt2==true && argn == this.over_all_score_val.length)) {
          this.barChartData = []
          this.needleValue = 0.0
          // for (let i = 0; i < this.over_all_score_val[argn].length; i++) {
          //   this.barChartData.push({ data: [this.over_all_score_val[argn][i]], label: this.skillVal[i] });
          // }

          var arr = []
          if(this.hidePt2==true){
          for (let i = 0; i < this.over_all_score_val[argn-1].length; i++) {
            arr.push(this.over_all_score_val[argn-1][i]);
          }

          this.barChartData.push({ data: arr, label: 'score' });

          this.needleValue = this.over_all_bspi[argn-1];
          console.log(this.needleValue)
        }
        else{
          for (let i = 0; i < this.over_all_score_val[argn].length; i++) {
            arr.push(this.over_all_score_val[argn][i]);
          }

          this.barChartData.push({ data: arr, label: 'score' });

          this.needleValue = this.over_all_bspi[argn];
          console.log(this.needleValue)
        }
        }

      }
    }
    else {
      this.barChartData = []
      this.needleValue = 0.0
      this.isBtnVis = false;
      this.skillkitRegular_btn = false;
      //  //console.log( this. over_all_score_val[argn]) 
      // for (let i = 0; i < this.score_val_Arr[argn].length; i++) {
      //   this.barChartData.push({ data: [this.score_val_Arr[argn][i]], label: this.skillVal[i] });
      // }

      var arr = []
      for (let i = 0; i < this.score_val_Arr[argn].length; i++) {
        arr.push(this.score_val_Arr[argn][i]);
      }

      this.barChartData.push({ data: arr, label: 'score' });

      this.needleValue = this.cycle_score_bspt_Arr[argn];
    }

  }

  // isskillkit_ava: boolean = false;
  skillkitRegular: boolean = true;

  Showskillkitchart() {
    this.isSkilkit = false;
    this.SubSkill_chk = false;
    //////function for setting skillkit chart data- starts//////
    // this.SubSkill_chk = false;
    // if (this.isskillkit_ava == true) {
    //console.log(this.skillkitVal)
    this.skillkitRegular = false
    if (this.skillkitVal.length > 0) {
      if (this.selectedChart == 2 || this.selectedChart == 3) {
        this.barChartData = []
        // for (let i = 0; i < this.skillkitVal[this.selectedChart - 2].length; i++) {
        //   this.barChartData.push({ data: [this.skillkitVal[this.selectedChart - 2][i]], label: this.skillVal[i] });
        // }

        var arr = []
        for (let i = 0; i < this.skillkitVal[this.selectedChart - 2].length; i++) {
          arr.push(this.skillkitVal[this.selectedChart - 2][i]);
        }

        this.barChartData.push({ data: arr, label: 'score' });


      } else {
        this.barChartData = []
        // for (let i = 0; i < this.skillVal.length; i++) {
        //   this.barChartData.push({ data: [0], label: this.skillVal[i] });
        // }

        var arr = []
        for (let i = 0; i < this.skillVal.length; i++) {
          arr.push(0);
        }

        this.barChartData.push({ data: arr, label: 'score' });
      }
    } else {
      this.barChartData = []
      // for (let i = 0; i < this.skillVal.length; i++) {
      //   this.barChartData.push({ data: [0], label: this.skillVal[i] });
      // }
      var arr = []
      for (let i = 0; i < this.skillVal.length; i++) {
        arr.push(0);
      }

      this.barChartData.push({ data: arr, label: 'score' });
    }
    // }
    //////function for setting skillkit chart data- ends//////
  }

  ///////////////////Subskill change///////////
  SubSkill_chk: boolean = false;
  sub_val_vis: boolean = false;
  sub_chk = 0

  SubSkill() {
    console.log('here')
    console.log(this.clp_val)
    let val = this.selectedChart;

    this.SubSkill_chk = true;
    this.isSkilkit = false;
    this.subskillname_Arr = []; this.subskillval_Arr = [];
    if (val == 1) {
      this.sub_val_vis = false;
      if (this.clp_val.length > 0) {
        var clp1inc = 0;
        for (this.i = 0; this.i < this.clp_val.length; this.i++) {
          if (this.clp_val[this.i][3] == "1") {
            this.sub_val_vis = true;
            this.subskillname_Arr[clp1inc] = this.clp_val[this.i][2];
            this.subskillval_Arr[clp1inc] = this.clp_val[this.i][0];
            clp1inc++;
          }
        }
      }
      else {
        this.sub_val_vis = false;
      }
    }
    else if (val == 2) {

      this.sub_val_vis = false;
      if (this.clp_val.length > 0) {
        var clp1inc1 = 0;
        for (this.i = 0; this.i < this.clp_val.length; this.i++) {
          if (this.clp_val[this.i][3] == "2") {
            this.sub_val_vis = true;
            this.subskillname_Arr[clp1inc1] = this.clp_val[this.i][2];
            this.subskillval_Arr[clp1inc1] = this.clp_val[this.i][0];
            clp1inc1++;
          }
        }
      }
      else {
        this.sub_val_vis = false;
      }

    }
    else if (val == 3) {
      this.sub_val_vis = false;
      if (this.clp_val.length > 0) {
        var clp1inc2 = 0;
        for (this.i = 0; this.i < this.clp_val.length; this.i++) {
          if (this.clp_val[this.i][3] == "3") {
            this.sub_val_vis = true;
            this.subskillname_Arr[clp1inc2] = this.clp_val[this.i][2];
            this.subskillval_Arr[clp1inc2] = this.clp_val[this.i][0];
            clp1inc2++;
          }
        }
      }
      else {
        this.sub_val_vis = false;
      }

    }
    else if (val == 4) {
      if (this.post_val.length > 0) {
        this.sub_val_vis = true;
        for (this.i = 0; this.i < this.post_val.length; this.i++) {
          this.subskillname_Arr[this.i] = this.post_val[this.i][2];
          this.subskillval_Arr[this.i] = this.post_val[this.i][0];
        }
      }
      else {
        this.sub_val_vis = false;
      }
    }
  }

  callass() {
   // debugger
    //////function for getting overall roadmap score  - starts//////
    this.load1 = true;
    this.ngxService.startLoader('loader-roadmap');
    this.conditional = [true,
      true, true, true, true, true, true, true, true,
      true, true, true, true, true, true, true, true,
      true, true, true, true, true, true, true, true,
      true];
    for (this.i = 0; this.i < 26; this.i++) {
      this.score_val_Arr[this.i] = [0, 0, 0, 0, 0];
      this.cycle_score_bspt_Arr[this.i] = 0;
    }
    localStorage.setItem("score_val_Arr", JSON.stringify(this.score_val_Arr));
    localStorage.setItem("cycle_score_bspt_Arr", JSON.stringify(this.cycle_score_bspt_Arr));
    localStorage.setItem("conditional", JSON.stringify(this.conditional));
    this.getallassscore.uid = localStorage.getItem("uid");
    this.getallassscore.year_status = 1;
    let date_val: Date;
    date_val = new Date();
    let hash_val = this.ip.gethash(date_val);
    this.getallassscore.timestamp = date_val.toString();
    this.getallassscore.hashcode = hash_val;

    this.reportService.getallassscoredetails(this.getallassscore).subscribe(
      (res) => {
        this.id = JSON.parse(JSON.stringify(res));
        if (this.id.status == "Failed") {
          this.ngxService.stopLoader('loader-roadmap');
          return 1;
        }
        var data: any = res;
        this.tooltipData = []
        data.cyclescore.forEach(element => {
          console.log(element)
          this.tooltipData.push(element[element.length - 1])
        });

        var data: any = res
        this.assesmentDetail = data.assdet[0]
        // this.blink = this.assesmentDetail.assesment != 'Post' && this.assesmentDetail.assesment != 'Initial' ?
        //   this.assesmentDetail.sessionstatus == 'completed' ?
        //     Number(this.assesmentDetail.assessment)
        //     : Number(this.assesmentDetail.assessment) - 1
        //   : this.assesmentDetail.assesment != 'Post' ? 25 : 0
        if (this.assesmentDetail.assessment == 'Post' && this.assesmentDetail.sessionstatus == 'completed') {
            this.after_post = true;
        }
        else {
          this.after_post = false;
        }
        console.log(this.assesmentDetail, 'this.assesmentDetail')
        this.blink = this.assesmentDetail.sessionstatus == 'completed' ?
          this.assesmentDetail.assessment != 'Post' && this.assesmentDetail.assessment != 'Initial' ?
            Number(this.assesmentDetail.assessment) :
            this.assesmentDetail.assessment == 'Post' ? 25 :
              0 : this.assesmentDetail.assessment != 'Post' && this.assesmentDetail.assessment != 'Initial' ?
            Number(this.assesmentDetail.assessment) - 1 :
            this.assesmentDetail.assessment == 'Post' ? 24 : -1


        this.highlight = this.assesmentDetail.assessment != 'Post' && this.assesmentDetail.assessment != 'Initial' ?
          Number(this.assesmentDetail.assessment) : this.assesmentDetail.assessment == 'Post' ? 25 : 0
        var datata
        // this.assesmentDetail.assessment = datata[0]
        // if (this.assesmentDetail.assessment != 'Initial') {
        //   this.highlight = Number(this.assesmentDetail.assessment)
        //   console.log('came1sasas')
        // }
        // else if (this.assesmentDetail.assessment == 'Post') {
        //   this.highlight = 25
        //   console.log('came2')
        // }
        // else {
        //   this.highlight = 0
        //   console.log('wise')

        // }
        console.log(this.blink, this.highlight, this.assesmentDetail.assessment != 'Initial', 'blinkblinkblinkblinkblinkblinkblinkblinkblinkblinkblinkblinkblinkblinkblinkblinkblinkblinkblinkblink')
        // this.tooltipData = []
        // for (let index = 0; index < 30; index++) {
        //   this.tooltipData.push(index + 30)

        // }

        if (this.id.code == "SA000") {

          for (this.i = 0; this.i < this.id.cyclescore.length; this.i++) {
            this.score_val_Arr[this.i][0] = parseInt(this.id.cyclescore[this.i][0]);
            this.score_val_Arr[this.i][1] = parseInt(this.id.cyclescore[this.i][1]);
            this.score_val_Arr[this.i][2] = parseInt(this.id.cyclescore[this.i][2]);
            this.score_val_Arr[this.i][3] = parseInt(this.id.cyclescore[this.i][3]);
            this.score_val_Arr[this.i][4] = parseInt(this.id.cyclescore[this.i][4]);
            this.cycle_score_bspt_Arr[this.i] = (this.score_val_Arr[this.i][0] + this.score_val_Arr[this.i][1] +
              this.score_val_Arr[this.i][2] + this.score_val_Arr[this.i][3] + this.score_val_Arr[this.i][4]) / 5;
            this.conditional[this.i] = false;
            if (this.i == 0) {
              console.log('came')
              for (let index = 0; index < this.score_val_Arr.length; index++) {
                if (this.score_val_Arr[index] == 0) {
                  this.conditional[this.i] = true;
                }

              }
            }
          }
          localStorage.setItem("score_val_Arr", JSON.stringify(this.score_val_Arr));
          localStorage.setItem("cycle_score_bspt_Arr", JSON.stringify(this.cycle_score_bspt_Arr));
          localStorage.setItem("conditional", JSON.stringify(this.conditional));
          this.dataSharingService.new_pass.next(1);
          this.ngxService.stopLoader('loader-roadmap');
          this.load1 = false
          // this.countsession = setInterval(() => { this.stopnewload(); }, 400);
          this.getReportScore();
        }
        else {
          if (this.id.code == "SA1061" ||
            this.id.code == "SA1041" || this.id.code == "SA1040" || this.id.code == "SA1039") {
            localStorage.clear();
            this.router.navigateByUrl("/login");
          }
          else if (this.id.code == "SA1138") {
            this.dataSharingService.new_pass.next(1);
            this.ngxService.stopLoader('loader-roadmap');
            this.countsession = setInterval(() => { this.stopnewload(); }, 400);

          }
        }
      });
    //////function for getting overall roadmap score  - ends//////
  }

  ngOnDestroy() { }

  //////Function for getting report score & bspi- starts//////
  iaVal: any = [];
  iaAvg: any;
  postVal: any = [];
  postAvg: any;
  over_all_score_val: any = [];
  over_all_bspi: any = [];
  isShowcompare: boolean = false;
  avg_skill_val: any = [];
  avg_bspi: any = [];
  skillkitVal: any = []
  barchart1name: any;
  barchart2name: any;
  chartPrevno: number = 0;
  //////////////////barchart////////////////////////////////
  // public barChartLabels: string[] = [''];
  public barChartType: string = 'horizontalBar';
  // horizontalBar
  public barChartLegend: boolean = false;
  barChartOptions: any;
  public barChartPlugins = [pluginDataLabels];
  public barChartData: ChartDataSets[] = [
    { data: [0, 0, 0, 0, 0], label: 'score' },

  ];

  public barChartData1: ChartDataSets[] = [
    { data: [0], label: 'Memory' },
    { data: [0], label: 'Visual Processing' },
    { data: [0], label: 'Focus And Attention' },
    { data: [0], label: 'Problem Solving' },
    { data: [0], label: 'Linguistics' }
  ];
  public barChartData3: ChartDataSets[] = [
    { data: [0], label: 'Memory' },
    { data: [0], label: 'Visual Processing' },
    { data: [0], label: 'Focus And Attention' },
    { data: [0], label: 'Problem Solving' },
    { data: [0], label: 'Linguistics' }
  ];


  public barChartColors: Color[] = [
    // { backgroundColor: '#33b5e6' },
    // { backgroundColor: '#aa66cd' },
    // { backgroundColor: '#98cb00' },
    // { backgroundColor: '#ffbb34' },
    // { backgroundColor: '#fe4543' }
  ]




  public barChartColors1: Color[] = [
    { backgroundColor: '#da0404' },
    { backgroundColor: '#ffc000' },
    { backgroundColor: '#92d050' },
    { backgroundColor: '#ff6800' },
    { backgroundColor: '#00b0f0' }
  ]
  /////////////////gauge//////////////////////////////////////////
  public canvasWidth: number;
  public centralLabel = ''
  public name = '';
  chartWidth: number;
  needleValue: number = 0.00;
  needleValue1: number;
  options: any;
  options1: any;
  options2: any;
  reportWords: any;
  assessWords: any;
  dwnreport: any;
  getReportScore() {
    let date_val: Date;
    date_val = new Date();
    let hash_val = this.ip.gethash(date_val);
    this.getReport.uid = localStorage.getItem("uid");
    this.getReport.eid = parseInt(localStorage.getItem("eid"));
    this.getReport.timestamp = date_val.toString();
    this.getReport.hashcode = hash_val;
    this.reportService.getreport(this.getReport).subscribe(
      (res) => {

        this.response = JSON.parse(JSON.stringify(res));
        var data: any = res
        this.avgScore = data.avg_bspi
        // this.getreportrank();
        if (this.response.code == "SA000") {
          this.iaVal = this.response.iaVal
          this.iaAvg = this.response.bspi_ia
          this.postVal = this.response.postVal
          this.postAvg = this.response.bspi_post
          this.avg_skill_val = this.response.avg_score_val;
          this.avg_bspi = this.response.avg_bspi;
          this.over_all_score_val = []
          if (this.avg_skill_val.length > 1) {
            this.isskillkitfn();
          } else {
            this.getSubSkillScore.uid = localStorage.getItem("uid");
            this.getSubSkillScore.eid = parseInt(localStorage.getItem("eid"));
            let date_val: Date;
            date_val = new Date();
            let hash_val = this.ip.gethash(date_val);
            this.getSubSkillScore.timestamp = date_val.toString();
            this.getSubSkillScore.hashcode = hash_val;
            this.reportService.getSubSkillScoreDetails(this.getSubSkillScore).subscribe(
              (res) => {
                this.subskillname_Arr = []; this.subskillval_Arr = [];
                this.response = JSON.parse(JSON.stringify(res));
                console.log(this.response)

                if (this.response.code == "SA000") {
                  this.ia_val = this.response.iaVal;
                  this.post_val = this.response.postVal;
                  this.clp_val = this.response.avg_score_val;
                  // console.log(this.ia_val)
                  //console.log(this.post_val)
                  console.log(this.clp_val)

                }
                else {
                  if (this.response.code == "SA1061" ||
                    this.response.code == "SA1041" || this.response.code == "SA1040" || this.response.code == "SA1039") {
                    localStorage.clear();
                    this.router.navigateByUrl("/login");
                  }
                  // if (this.response.code == "SA1234") {              

                  // }
                }
              });
          }

          if (this.iaVal.length != 0) {
            this.assesment_types.push(this.radmapWords[2])
            this.over_all_score_val.push(this.iaVal[0])
            this.over_all_bspi.push(this.iaAvg)
          }
          if (this.avg_skill_val.length != 0) {
            for (let i = 0; i < this.avg_skill_val.length; i++) {
              if (i == 0) {
                this.assesment_types.push(this.radmapWords[40])
              } else {
                this.assesment_types.push(this.radmapWords[41] + " - C" + (i))
              }
              this.over_all_score_val.push(this.avg_skill_val[i])
              this.over_all_bspi.push(this.avg_bspi[i])
            }

          }
          if (this.postVal.length != 0) {
            //   console.log(this.postVal +" jedsklfjklsdjflkdjdlfs")
            // if (this.postVal[0].length == 5) {
            //     this.after_post = true;
            // }
            // else {
            //   this.after_post = false;
            // }

            this.assesment_types.push(this.radmapWords[45])
            this.over_all_score_val.push(this.postVal[0])
            this.over_all_bspi.push(this.postAvg)
          }

          if (this.assesment_types.length == 1) {
            this.isShowcompare = false;
          } else if (this.assesment_types.length == 0) {
            this.assesment_types.push(this.radmapWords[2])
            this.isShowcompare = false;
          } else {
            this.isShowcompare = true;
          }


          this.barchart1name = this.assesment_types[this.chartPrevno]

          if (this.over_all_score_val.length == 0) {
            this.needleValue = 0.00;
            localStorage.setItem("rep3_needleValue", this.needleValue.toString());
            this.dataSharingService.isrep3_gauge.next(1);
          } else {
            this.barChartData = []
            this.barChartData1 = []
            //console.log(this.over_all_score_val)
            var arr = []
            for (let i = 0; i < this.over_all_score_val[0].length; i++) {
              arr.push(this.over_all_score_val[0][i])
              // this.barChartData.push({ data: [this.over_all_score_val[0][i]], label: this.skillVal[i] });
            }
            this.barChartData.push({ data: arr, label: 'score' });

            this.needleValue = this.over_all_bspi[this.chartPrevno];
            localStorage.setItem("rep3_needleValue", this.needleValue.toString());
            this.dataSharingService.isrep3_gauge.next(1);
            this.starCalculationFn();
            this.ngxService.stopLoader('loader-roadmap');
            this.countsession = setInterval(() => { this.stopnewload(); }, 400);
          }
        } else {
          if (this.response.code == "SA1061" ||
            this.response.code == "SA1041" || this.response.code == "SA1040" || this.response.code == "SA1039") {
            localStorage.clear();
            this.router.navigateByUrl("/login");
          }
        }
        //////API for getting report score & bspi- starts//////
      },
      (err) => {
      }
    )
  }

  strCnt: any = []
  allstrCnt: any = []
  memStrcnt: number;
  vpStrcnt: number;
  faStrcnt: number;
  psStrcnt: number;
  linStrcnt: number;
  strCnt_all: number = 0;
  strCnt_all1: number = 0;
  isoverallstar: boolean = true;

  starCalculationFn() {
    //////function for calculating star- starts//////
    if (parseInt(localStorage.getItem("curr_assess_login")) == 1) {
      this.isoverallstar = true;
      this.strCnt = this.iaVal[0]
      if (this.iaVal.length != 0) {
        for (let j = 0; j < this.iaVal[0].length; j++) {
          if (this.strCnt[j] >= 0 && this.strCnt[j] < 20) {

            this.strCnt_all1 = 0
          }
          if (this.strCnt[j] >= 20 && this.strCnt[j] <= 40) {
            this.strCnt_all1 = 1;
          } else if (this.strCnt[j] > 40 && this.strCnt[j] <= 60) {

            this.strCnt_all1 = 2;
          } else if (this.strCnt[j] > 60 && this.strCnt[j] <= 80) {

            this.strCnt_all1 = 3;
          } else if (this.strCnt[j] > 80 && this.strCnt[j] <= 90) {

            this.strCnt_all1 = 4;
          } else if (this.strCnt[j] > 90 && this.strCnt[j] <= 100) {

            this.strCnt_all1 = 5;
          }
          this.strCnt_all += this.strCnt_all1;

        }
      }
    } else if (parseInt(localStorage.getItem("curr_assess_login")) == 3) {

      this.isoverallstar = true;
      this.strCnt = this.postVal[0]
      if (this.postVal.length != 0) {
        for (let j = 0; j < this.postVal[0].length; j++) {
          if (this.strCnt[j] >= 0 && this.strCnt[j] < 20) {

            this.strCnt_all1 = 0
          }
          if (this.strCnt[j] >= 20 && this.strCnt[j] <= 40) {
            this.strCnt_all1 = 1;
          } else if (this.strCnt[j] > 40 && this.strCnt[j] <= 60) {

            this.strCnt_all1 = 2;
          } else if (this.strCnt[j] > 60 && this.strCnt[j] <= 80) {

            this.strCnt_all1 = 3;
          } else if (this.strCnt[j] > 80 && this.strCnt[j] <= 90) {

            this.strCnt_all1 = 4;
          } else if (this.strCnt[j] > 90 && this.strCnt[j] <= 100) {

            this.strCnt_all1 = 5;
          }
          this.strCnt_all += this.strCnt_all1;
        }
      }
    } else {
      this.isoverallstar = false;
    }
    //////function for calculating star- ends//////
  }

  getSubSkillScore = new getSubSkillScore();
  subskillname_Arr = []; subskillval_Arr = [];
  ia_val; post_val; clp_val;
  isskillkitfn() {
    //////function for getting skillkit report status- starts//////
    // console.log("coming this")
    this.getskill.uid = localStorage.getItem("uid");
    this.getskill.eid = parseInt(localStorage.getItem("eid"));
    let date_val: Date;
    date_val = new Date();
    let hash_val = this.ip.gethash(date_val);
    this.getskill.timestamp = date_val.toString();
    this.getskill.hashcode = hash_val;

    this.reportService.getskillkit(this.getReport).subscribe(
      (res) => {
        this.response = JSON.parse(JSON.stringify(res));
        //console.log(this.response)
        if (this.response.code == "SA000") {
          this.skillkitVal = this.response.skillkitval;
          this.getSubSkillScore.uid = localStorage.getItem("uid");
          this.getSubSkillScore.eid = parseInt(localStorage.getItem("eid"));
          let date_val: Date;
          date_val = new Date();
          let hash_val = this.ip.gethash(date_val);
          this.getSubSkillScore.timestamp = date_val.toString();
          this.getSubSkillScore.hashcode = hash_val;
          this.load1 = true;
          this.ngxService.startLoader('loader-reports');
          this.reportService.getSubSkillScoreDetails(this.getSubSkillScore).subscribe(
            (res) => {
              this.subskillname_Arr = []; this.subskillval_Arr = [];
              this.response = JSON.parse(JSON.stringify(res));
              //console.log(this.response)

              if (this.response.code == "SA000") {
                this.ia_val = this.response.iaVal;
                this.post_val = this.response.postVal;
                this.clp_val = this.response.avg_score_val;
                //console.log(this.ia_val)
                //console.log(this.post_val)
                //console.log(this.clp_val)
                this.ngxService.stopLoader('loader-reports');
                this.countsession = setInterval(() => { this.stopnewload(); }, 400);

              }
              else {
                if (this.response.code == "SA1061" ||
                  this.response.code == "SA1041" || this.response.code == "SA1040" || this.response.code == "SA1039") {
                  localStorage.clear();
                  this.router.navigateByUrl("/login");
                }
                // if (this.response.code == "SA1234") {              

                // }
              }
            });
        } else {
          if (this.response.code == "SA1061" ||
            this.response.code == "SA1041" || this.response.code == "SA1040" || this.response.code == "SA1039") {
            localStorage.clear();
            this.router.navigateByUrl("/login");
          }
          if (this.response.code == 'SA026') {
            this.getSubSkillScore.uid = localStorage.getItem("uid");
            this.getSubSkillScore.eid = parseInt(localStorage.getItem("eid"));
            let date_val: Date;
            date_val = new Date();
            let hash_val = this.ip.gethash(date_val);
            this.getSubSkillScore.timestamp = date_val.toString();
            this.getSubSkillScore.hashcode = hash_val;
            this.load1 = true;
            this.ngxService.startLoader('loader-reports');
            this.reportService.getSubSkillScoreDetails(this.getSubSkillScore).subscribe(
              (res) => {
                this.subskillname_Arr = []; this.subskillval_Arr = [];
                this.response = JSON.parse(JSON.stringify(res));
                //console.log(this.response)

                if (this.response.code == "SA000") {
                  this.ia_val = this.response.iaVal;
                  this.post_val = this.response.postVal;
                  this.clp_val = this.response.avg_score_val;
                  // //console.log(this.ia_val)
                  // //console.log(this.post_val)
                  // //console.log(this.clp_val)
                  this.ngxService.stopLoader('loader-reports');
                  this.countsession = setInterval(() => { this.stopnewload(); }, 400);
                }
                else {
                  if (this.response.code == "SA1061" ||
                    this.response.code == "SA1041" || this.response.code == "SA1040" || this.response.code == "SA1039") {
                    localStorage.clear();
                    this.router.navigateByUrl("/login");
                  }
                  // if (this.response.code == "SA1234") {              

                  // }
                }
              });
          }
        }
      }
    )
    //////function for getting skillkit report status- starts//////
  }

  // public canvasWidth: number;
  // public centralLabel = ''
  // public name = '';
  // needleValue: number = 0.00;
  // options: any;
  // options1: any;
  // options2: any;

  ///////////////Resize canvasWidth gauge/////////////////////
  onResize() {
    //////function for getting screen size  - starts//////
    if (window.innerWidth > 1500) {
      this.canvasWidth = window.innerWidth / 5;
    } else if (window.innerWidth > 1000) {
      this.canvasWidth = window.innerWidth / 5;
    } else if (window.innerWidth > 500) {
      this.canvasWidth = window.innerWidth / 3;
    } else {
      this.canvasWidth = window.innerWidth / 2;
    }
    //////function for getting screen size  - ends//////
  }

  //////////////////////////////////////////////////
  @HostListener('window:resize', ['$event'])
  onResize1(event) {
    // console.log(event.target.innerWidth);
    // location.reload();

  }
  radmapWords;
  mob_val = true;
  countsession;
  stopnewload() {
    clearInterval(this.countsession);
    this.load1 = false;
  }

  getProfile = new profileget();
  skillVal = ["Memory", "Visual Processing", "Focus And Attention", "Problem Solving", "Linguistics"]
  ngOnInit() {
    this.barChartColors = [
      { backgroundColor: ['#fe2e2e', '#ffbd4c', '#98c865', '#f18f4e', '#7fd0ee'] }
    ]
    // this.barChartColors = [
    //   {
    //     backgroundColor: this.isDark ? ['#33b5e6', '#aa66cd', '#98cb00', '#ffbb34', '#fe4543'] : ['#33b5e6', '#aa66cd', '#98cb00', '#ffbb34', '#fe4543'],
    //     // backgroundColor: this.isDark ? ['#f25858', '#ffbd4c', '#98c865', '#f18f4e', '#7fd0ee'] : ['#f25858', '#ffbd4c', '#98c865', '#f18f4e', '#7fd0ee'],
    //     // borderColor: 'red',
    //   }
    // ]
    this.assesData = Number(this.LocaldatastorageService_ts.getdata("ass_status"))
console.log(this.assesData,"assesData")


    //////Initail function for defining basic varibles  - starts//////
    if (localStorage.getItem("uid") == "" || localStorage.getItem("uid") == null) {
      this.router.navigateByUrl('/login');
    } else {
      var d = window.innerWidth;
      console.log("window.innerWidth" + window.innerWidth)
      this.color_val[parseInt(localStorage.getItem("currenttheme"))] = true;
      this.theme.currenttheme.subscribe(themename => this.themechange = themename)
      this.element = document.querySelector('.' + this.themechange)
      this.colorVal = getComputedStyle(this.element).getPropertyValue('--base-color');
      this.color4 = this.colortheme[0];
      this.color3 = getComputedStyle(this.element).getPropertyValue('--accent-color');
      this.colortheme_bg = this.colortheme[parseInt(localStorage.getItem("isdark"))];
      this.colortheme_bg_base_sec = this.colortheme_base_sec[parseInt(localStorage.getItem("isdark"))];
      this.colortheme_txt1 = this.colortheme_txt1_Arr[parseInt(localStorage.getItem("isdark"))];
      if (parseInt(localStorage.getItem("isdark")) == 0) {
        this.colortheme_txt = this.colortheme[1];
        // this.color1 = this.colortheme_base[0];
        // this.color2 = '#E90B89';
        this.color2 = "linear-gradient(" + this.colortheme_base[0] + "," + this.colortheme_base[0] + "), radial-gradient(circle at top left, #64676a   , #64676a  )";

        this.color1 = "linear-gradient(" + this.colortheme_base[0] + "," + this.colortheme_base[0] + "), radial-gradient(circle at top left, #E80A89, #F96816)";
      }
      else {
        this.colortheme_txt = this.colortheme[0];
        // this.color1 = this.colortheme_base[1];
        // this.color2 = '#E90B89';
        this.color2 = "linear-gradient(" + this.colortheme_base[1] + "," + this.colortheme_base[1] + "), radial-gradient(circle at top left, #64676a   , #64676a  )";
        this.color1 = "linear-gradient(" + this.colortheme_base[1] + "," + this.colortheme_base[1] + "), radial-gradient(circle at top left, #E80A89, #F96816)";
      }
      var width = window.innerWidth;
      if (width < 768 && window.orientation != 90) {
        this.mob_val = false;
        this.val_val = 1;
        //console.log('mobile device detected' + window.orientation)
      } else if (width >= 768 && width <= 992) {
        //console.log('tablet detected');
        this.mob_val = true;
        this.val_val = 0;

      } else {
        this.mob_val = true;
        this.val_val = 0;
        //console.log('desktop detected')

      }

      if (localStorage.getItem("isfullschudle_stored") == '0') {
        this.isfull_val = false;
      }
      else {
        this.isfull_val = true;
      }
    }


    // this.options2 = {
    //   hasNeedle: true,
    //   needleColor:  'gray',
    //   needleStartValue: 50,
    //   arcColors: ["#c02127", "#f36723", "#fdc012", "#95c954", "#2be035"],
    //   arcDelimiters: [20, 40, 60, 80],
    //   rangeLabel: ["0", "100"],
    //   responsive: true
    // };
    if (window.innerWidth > 1500) {
      this.canvasWidth = window.innerWidth / 5;
    } else if (window.innerWidth > 1000) {
      this.canvasWidth = window.innerWidth / 5;
    } else if (window.innerWidth > 500) {
      this.canvasWidth = window.innerWidth / 3;
    } else {
      this.canvasWidth = window.innerWidth / 2;
    }

    /////////////////////Get report/////////////////////////

    this.radmapWords = JSON.parse(localStorage.getItem('langwords') || '[]');

    // this.skillVal = [this.radmapWords[13], this.radmapWords[14], this.radmapWords[15],
    //   this.radmapWords[16], this.radmapWords[17]]
    ////////////////////////barchart/////////////////////
    this.barChartData = [
      { data: [0, 0, 0, 0, 0], label: 'score' }

    ];
    this.barchart1name = this.radmapWords[2]
    this.barChartOptions = {
      scaleShowVerticalLines: false,
      responsive: true,
      scales: {
        xAxes: [
          {
            display: true,
            ticks: {
              beginAtZero: true,
              max: 100,
              fontSize: 12,
              fontColor: this.colortheme_txt
            },
            gridLines: { color: 'rgb(110, 110, 110)' }
          }
        ],
        yAxes: [
          {
            display: true,
            ticks: {
              beginAtZero: true,
              max: 100,
              fontSize: 13,
              fontColor: this.colortheme_txt
            },
            gridLines: { color: 'rgb(110, 110, 110)' }
          }
        ],

      },
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'start',
          offset: -1,
          font: {
            size: 12
          },
          color: this.colortheme_txt,
          formatter: function (value, context) {
            return value != 0 ? value : '';
          }
        }
      },
      legend: {
        show: false,
        position: 'bottom',
        labels: {
          boxWidth: 15,
          boxHeight: 1,
          fontSize: 18,
          fontStyle: 'Roboto',
          fontColor: this.colortheme_txt
        },
      }
    };

    /////////////////gauge////////////////
    this.options = {
      hasNeedle: true,
      needleColor: this.colortheme_txt,
      needleStartValue: 0,
      arcColors: ["#c02127", "#f36723", "#fdc012", "#95c954", "#2be035"],
      arcDelimiters: [20, 40, 60, 80],
      rangeLabel: ["0", "100"],
      responsive: true
    };
    this.options1 = {
      hasNeedle: true,
      needleColor: this.colortheme_txt,
      needleStartValue: 0,
      arcColors: ["#c02127", "#f36723", "#fdc012", "#95c954", "#2be035"],
      arcDelimiters: [20, 40, 60, 80],
      rangeLabel: ["0", "100"],
      responsive: true
    };
    this.options2 = {
      hasNeedle: true,
      needleColor: this.colortheme_txt,
      needleStartValue: 0,
      arcColors: ["#c02127", "#f36723", "#fdc012", "#95c954", "#2be035"],
      arcDelimiters: [20, 40, 60, 80],
      rangeLabel: ["0", "100"],
      responsive: true,
      legend: {
        display: true,
        labels: {
          fontColor: 'green'
        }
      }
    };
    if (window.innerWidth > 1500) {
      this.canvasWidth = window.innerWidth / 5;
    } else if (window.innerWidth > 1000) {
      this.canvasWidth = window.innerWidth / 5;
    } else if (window.innerWidth > 500) {
      this.canvasWidth = window.innerWidth / 3;
    } else {
      this.canvasWidth = window.innerWidth / 2;
    }


    this.callass();

    //////Initail function for defining basic varibles  - ends//////
    if (parseInt(localStorage.getItem("curr_assess_login")) > 1 && parseInt(localStorage.getItem("assessment_check")) > 1) {
      this.istrophybtn = true;
    }
  }
  istrophybtn: boolean = false;
  getskill = new getskill();

  backFn() {
    this.load1 = true;
    this.ngxService.startLoader('loader-reports');
    this.isRoadMap = true;
    localStorage.setItem("roadback", "0");
    this.ngxService.stopLoader('loader-reports');
    this.countsession = setInterval(() => { this.stopnewload(); }, 400);
  }
  certificatefn() {
    //////function for generate certificate //////
    this.getProfile.uid = localStorage.getItem("uid");
    let date_val: Date;
    date_val = new Date();
    let hash_val = this.ip.gethash(date_val);
    this.getProfile.timestamp = date_val.toString();
    this.getProfile.hashcode = hash_val;
    this.load1 = true;
    this.ngxService.startLoader('loader-reports');
    this.ProfileService_ts.getprofile(this.getProfile).subscribe(
      (res) => {
        this.response = JSON.parse(JSON.stringify(res));
        if (this.response.code == "SA000") {
          console.log(this.response.gradename);
          var grade_val = 0;

          if (this.response.gradename == "Grade 1")
            grade_val = 1;
          else if (this.response.gradename == "Grade 2")
            grade_val = 2;
          else if (this.response.gradename == "Grade 3")
            grade_val = 3;
          else if (this.response.gradename == "Grade 4")
            grade_val = 4;
          else if (this.response.gradename == "Grade 5")
            grade_val = 5;
          else if (this.response.gradename == "Grade 6")
            grade_val = 6;
          else if (this.response.gradename == "Grade 7")
            grade_val = 7;
          else if (this.response.gradename == "Grade 8")
            grade_val = 8;
          else if (this.response.gradename == "Grade 9")
            grade_val = 9;
          else if (this.response.gradename == "Grade 10")
            grade_val = 10;
          else if (this.response.gradename == "Grade 11")
            grade_val = 11;
          else if (this.response.gradename == "Grade 12")
            grade_val = 12;

          console.log(grade_val);

          // this.post('http://hotsa.skillangels.com/certificateV1/certs.php', {
          //   uid : localStorage.getItem("uid"),
          //   currentLevel : grade_val.toString(),
          //   name :localStorage.getItem("uname")
          // });

          this.getcert.uid = localStorage.getItem("uid");
          this.getcert.currentLevel = grade_val.toString();
          this.getcert.name = localStorage.getItem("uname");
          this.reportService.getcertdetails(this.getcert).subscribe(
            (res1) => {
              this.response = JSON.parse(JSON.stringify(res1));
              console.log(this.response);
              if (this.response.status == 1) {

                // this.crt_url = this.url.dlcertificateUrl + localStorage.getItem("uid") + ".png";
                this.crt_url = this.url.serverurl + 'resources1/' + localStorage.getItem("uid") + ".png";
                console.log(this.crt_url)
                this.img_src = true;
                this.ngxService.stopLoader('loader-reports');
                this.countsession = setInterval(() => { this.stopnewload(); }, 400);
              }
              else {

                this.img_src = false;
                this.ngxService.stopLoader('loader-reports');
                this.countsession = setInterval(() => { this.stopnewload(); }, 400);
              }


            }
          )
        }
        else {
          console.log("unable to generate");
          this.img_src = false;
          this.ngxService.stopLoader('loader-reports');
          this.countsession = setInterval(() => { this.stopnewload(); }, 400);
        }
      });


    // var res=  this.HttpClient_ts.get("http://hotsa.skillangels.com/certificateV1/certs.php");
    // console.log( JSON.parse(JSON.stringify(res)))
    // return this.HttpClient_ts.get('https://hotsa.skillangels.com/certificateV1/certs.php') // <-- Here
    // .subscribe(
    // (res: Response) => {
    //   var data = res;
    //   console.log(data)
    // },
    // (err: Error) => console.log(err)
    // );


    this.modalService.open(this.certificate, { centered: true });
  }

  cli() {
    //////function for open certificate popup //////
    this.modalService.open(this.certificate, { centered: true });
  }
  ///////////////////////Trophies//////////////////
  istrophy: boolean = false
  trophy() {
    this.istrophy = true;
  }
  isleaderboard: boolean = false
  leader() {
    this.isleaderboard = true;
  }


  sharePopupStatus: boolean = false;
  sharePopup() {
    this.sharePopupStatus = true;
  }
  cloaseCard() {
    this.sharePopupStatus = false;
  }
  shareImg: any = '';
  share(e) {
    let element = document.getElementById("share");
    // htmlToImage.toPng(element)
    // .then(function (dataUrl) {
    //   var img = new Image();
    //   img.src = dataUrl;
    //   this.shareImg = img;
    //    let link = document.createElement("a");

    //   // download(dataUrl, 'my-node.png');
    // })
    // .catch(function (error) {
    //   console.error('oops, something went wrong!', error);
    // });
    html2canvas(element, {}).then(function (canvas) {
      // console.log(canvas.toDataURL('image/jpeg', 0.5))
      // this.shareImg = canvas.toDataURL('image/jpeg', 0.5)
      // Convert the canvas to blob
      canvas.toBlob(function (blob) {
        // To download directly on browser default 'downloads' location
        // console.log(blob)
        let link = document.createElement("a");
        link.target = '_blank';
        link.download = "image.png";
        // link.href ='https://www.facebook.com/sharer/sharer.php?u='+URL.createObjectURL(blob);
        link.href = 'https://www.facebook.com/sharer/sharer.php?u=' + canvas.toDataURL('image/jpeg', 0.5);
        // link.href =URL.createObjectURL(blob);
        link.click();

        // To save manually somewhere in file explorer
        // window.saveAs(blob, 'image.png');

      }, 'image/png');
    });
    this.sharePopupStatus = false;
  }


  dwnldInit(card) {

    var sa = card == 1 ? 'Detailed Assessment' : card == 2 ? 'Personalized Training Cycle 1' : card == 3 ? 'Personalized Training Cycle 2' : ''

    console.log(card)
    let date_val: Date;
    date_val = new Date();
    // this.iaVal = this.response.iaVal
    // this.iaAvg = this.response.bspi_ia
    let hash_val = this.ip.gethash(date_val);
    let game_score = [];
    if (Number(localStorage.getItem('rep3_needleValue')) != 0) {
      game_score = this.iaVal[0];
    }
    if (Number(localStorage.getItem('rep4_needleValue')) != 0) {
      game_score = this.iaVal[1];
    }
    if (Number(localStorage.getItem('rep5_needleValue')) != 0) {
      game_score = this.iaVal[2];
    }

    var report_card
    if (card == 0) {
      report_card = {
        uid: String(localStorage.getItem('uid')),
        timestamp: date_val.toString(),
        hashcode: hash_val,
      }
    } else if (card >= 1 && card <= 3) {
      report_card = {
        uid: String(localStorage.getItem('uid')),
        timestamp: date_val.toString(),
        hashcode: hash_val,
        curr_ass: sa
      }
    }
    //  else if (card == 3) {
    //   report_card = {
    //     name: localStorage.getItem('uname'),
    //   }
    // }
    console.log(this.Assesment_names_val, 'Assesment_names_val', report_card)


    //Detailed Assessment,Personalized Training Cycle 1,Personalized Training Cycle 2





    // const report_card = {
    //   // type: 1,
    //   uid: String(localStorage.getItem('uid')),
    //   timestamp: date_val.toString(),
    //   hashcode: hash_val,
    //   // name: localStorage.getItem('uname'),
    //   // curr_ass:"Detailed Assessment"
    //   // grade: 1,
    //   // bspi: this.iaAvg,
    //   // mem: Number(game_score[0]),
    //   // vp: Number(game_score[1]),
    //   // fa: Number(game_score[2]),
    //   // ps: Number(game_score[3]),
    //   // lin: Number(game_score[4]),
    // };
    // if (card == 2) {
    //   report_card.type = 2;
    // } else if (card == 3) {
    //   report_card.type = 3;
    // }
    this.reportService.getintcertdetails(report_card, card >= 1 && card <= 3 ? 2 : card == 4 ? 3 : 0).subscribe(
      (res1: any) => {
        this.response = JSON.parse(JSON.stringify(res1));
        console.log(this.response);
        if (this.response.status == 1) {

          // this.crt_url = this.url.dlcertificateUrl + localStorage.getItem("uid") + ".png";
          // this.crt_url = this.url.serverurl + 'resources1/' + localStorage.getItem("uid") + ".png";
          // console.log(this.crt_url)
          // this.img_src = true;
          this.toDataURL(this.url.serverurl + 'cerdwnld/' + res1.pngname,
            function (dataUrl) {
              console.log(dataUrl);
              const a: any = document.createElement('a');
              a.href = dataUrl;
              a.download = (localStorage.getItem("uname"))[0].toUpperCase() + (localStorage.getItem("uname")).substr(1).toLowerCase() + ` Certificate.png`;
              document.body.appendChild(a);
              a.style = 'display: none';
              a.click();
              a.remove();
            })

          this.ngxService.stopLoader('loader-reports');
          this.countsession = setInterval(() => { this.stopnewload(); }, 400);
        }
        else {

          this.img_src = false;
          this.ngxService.stopLoader('loader-reports');
          this.countsession = setInterval(() => { this.stopnewload(); }, 400);
        }


      }
    )
  }
}
