export class getgame {
    uid: string;
    eid: number;
    year_status: number;
    timestamp: string;
    hashcode: string;
}

export class getScore {
    uid: string;
    eid: number;
    date: Date;
    ass_status: number;
    year_status: number;
    istrainingdata: number;
    timestamp: string;
    hashcode: string;
}


export class getTrophy {
    uid: string;
    ass_status: number;
    eid: number;
    year_status: number;
    timestamp: string;
    hashcode: string;
}

export class getquescnt {
    uid: string;
    eid: number;
    date: Date;
    ass_status: number;
    year_status: number;
    istrainingdata: number;
    timestamp: string;
    hashcode: string;
}

export class getsnd {
    uid: string;
    eid: number;
    ass_status_id: number;
    year_status: number;
    timestamp: string;
    hashcode: string;
}
export class getDaChk {
    uid: string;
    year_status: number;
    timestamp: string;
    hashcode: string;
}
export class getgamec2 {
    uid: string;
    eid: number;
    ass_status_id: number;
    year_status: number;
    timestamp: string;
    hashcode: string;
}

export class getorggame {
    uid: string;
    eid: number;
    year_status: number;
    ass_status_id: number;
    date: Date;
    mem: number;
    vp: number;
    fa: number;
    ps: number;
    lin: number;
    memnam: string;
    vpnam: string;
    fanam: string;
    psnam: string;
    linnam: string;
    timestamp: string;
    hashcode: string;
}


export class getass2trainchk {
    uid: string;
    eid: number;
    ass_status_id: number;
    year_status: number;
    timestamp: string;
    hashcode: string;
}

export class pgmstatus {
    uid: string;
    year_status: number;
    timestamp: string;
    hashcode: string;
}