// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
  // serverurl: string = "https://devschool-ws.skillangels.com/";
  //   gameurl: string = 'https://devschool-ws.skillangels.com/game';
  //   skillkiturl: string = 'https://devschool-ws.skillangels.com/game';
  //   puzzleurl: string = 'https://devschool-ws.skillangels/menu';
  //   soundUrl: string = "assets/sound/";
  //   imageurl: string = "https://devschool-ws.skillangels.com/resources/";
  //   galleryurl: string = "assets/images/Gallery/";
  //   gencertificateUrl: string = "https://devschool-ws.skillangels/certificateV1/certs.php";
  //   dlcertificateUrl: string = "https://devschool-ws.skillangels/certificateV1/createdCerts/";
  //   trailUrl: string = "https://devschool-ws.skillangels/index.php/trial/";
  //   homeUrl:string="https://devschool-ws.skillangels.com";
  //   regUrl:string="https://devschool-ws.skillangels/index.php/trial/registration";

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
