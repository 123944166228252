import { Component, OnInit, ViewChild } from "@angular/core";
import { accLogin, sessionid } from ".././services/login/loginAPI";
import { LoginAPIService } from ".././services/login/login-api.service";
import { ThemeService } from "../services/profile/theme.service";
import { LanguageService } from "../services/profile/language.service";
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { v4 as uuid } from 'uuid';
import { IpService } from '../services/ip/ip.service';
import { HttpClient } from "@angular/common/http";
@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {
  @ViewChild("schedule", { static: false }) schedule;
  @ViewChild("crctschedule", { static: false }) crctschedule;
  @ViewChild("sessionshow", { static: false }) sessionshow;
  clientURL: string;
  accLogin = new accLogin();
  public username: string;
  ipAddress: any;
  public password: string;
  response: any;
  response1: any;
  entrytheme: number;
  themechange: any;
  entrylang: number;
  langtxt: any;
  langmap = new Map();
  langresponse: any;
  errmsg: string;
  iserr: boolean;

  ngOnInit() {
    console.log(window.innerWidth);
    this.iserr = false;
    console.log(localStorage.getItem("uid"));

    // if (
    //   localStorage.getItem("uid") != "" &&
    //   localStorage.getItem("uid") != null
    // ) {
      this.router.navigateByUrl("/menu");
    // }
    // else {
    // this.callLanguages();
    // }
  }


  constructor(
    private LoginAPIService: LoginAPIService,
    private theme: ThemeService,
    private lang: LanguageService,
    private ngxService: NgxUiLoaderService,
    private router: Router,
    private modalService: NgbModal,private ip: IpService,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient
    ) {
    this.username = (JSON.parse(JSON.stringify(this.activatedRoute.queryParams)))._value.Data1;
    this.password = (JSON.parse(JSON.stringify(this.activatedRoute.queryParams)))._value.Data2;
    this.clientURL = (JSON.parse(JSON.stringify(this.activatedRoute.queryParams)))._value.Data3;
    console.log("this.username" + this.username);
  }

  callLanguages() {
    this.LoginAPIService.getLanguages().subscribe(res => {
      this.langresponse = JSON.parse(JSON.stringify(res));
      if (this.langresponse.code == "SA000") {
        let languages = new Array();
        for (let i = 0; i < this.langresponse.result.length; i++) {
          languages.push(this.langresponse.result[i].lang_name);
        }
        this.lang.langListname = languages;
        if (this.clientURL != "" && this.clientURL != null) {
          localStorage.setItem("clientURL", this.clientURL);
          this.checkLogin();
        }
        else {
          localStorage.setItem("clientURL", "");

        }
      }
    });
  }
  uname: string;
  checkLogin() {
    this.ngxService.start();

    this.theme.currenttheme.subscribe(
      themename => (this.themechange = themename)
    );
    this.lang.currentLang.subscribe(lang => (this.langtxt = lang));
    this.accLogin.username = this.username;
    this.accLogin.password = this.password;

    if (this.accLogin.username != "" && this.accLogin.username != null && this.accLogin.password != "" && this.accLogin.password != null) {
      console.log(this.username);
      this.LoginAPIService.getAccDetails(this.accLogin).subscribe(res => {
        console.log(JSON.parse(JSON.stringify(res)));
        this.response = JSON.parse(JSON.stringify(res));
        if (this.response.code == "SA000") {
          localStorage.setItem("dob_password", (this.password).toString());
          this.uname = this.response.result[0].name
          this.ngxService.stop();
          console.log(!this.response.result[0].current_session_id)
          if (!this.response.result[0].current_session_id) {
            console.log("entered")
            if (this.response.curr_assess == 1) {
              this.callSiteWords();
              console.log("Entered")
            }
            else if (this.response.isschedule == 0) {
              this.callSiteWords();
              console.log("Entered1")
            } else {
              this.callSiteWords();
            }
          } else {
            this.callSiteWords();
          }

        } else {
          this.ngxService.stop();
          window.open(localStorage.getItem("clientURL") + '?Data1=InvalidUserOrPassword', "_self");
          this.iserr = true;
        }
      });
    }
    else {
      this.ngxService.stop();
      window.open(localStorage.getItem("clientURL") + '?Data1=UserOrPasswordIsNull', "_self");
      this.iserr = true;
    }
  }

  public list: string[] = [];
  session_id = new sessionid();
  gen() {
    this.list.push(uuid());
    console.log(this.list[0])
    this.session_id.uid = localStorage.getItem("uid")
    this.session_id.sessionid = this.list[0]
    this.LoginAPIService.setSessionid(this.session_id).subscribe(res => {

      this.response = JSON.parse(JSON.stringify(res));
      console.log(this.response)

      if (this.response.code == "SA000") {
        localStorage.setItem("session_id", this.list[0]);

        this.http.get("https://api.ipify.org/?format=json").subscribe(
          data => {
            let adata: any = data
            this.ipAddress = adata.ip;
          localStorage.setItem("my_ip", this.ipAddress.toString())
          console.log("this.ipAddress" + this.ipAddress);
          if (localStorage.getItem("my_ip") != "") {
            this.lang.loadSiteWords(this.lang_val, 0);
          }
          },
          error => {
            this.ipAddress = '-';
            localStorage.setItem("my_ip", this.ipAddress.toString())
            console.log("this.ipAddress" + this.ipAddress);
            if (localStorage.getItem("my_ip") != "") {
              this.lang.loadSiteWords(this.lang_val, 0);
            }
          })

        // this.ip.getIPAddress().subscribe((res1: any) => {
        //   this.ipAddress = res1.ip;
        //   localStorage.setItem("my_ip", this.ipAddress.toString())
        //   console.log("this.ipAddress" + this.ipAddress);
        //   if (localStorage.getItem("my_ip") != "") {
        //     this.lang.loadSiteWords(this.lang_val, 0);
        //   }
        // });
        this.lang.loadSiteWords(this.lang_val, 0);

      } else {

      }
    });
  }
  lang_val: any;
  callSiteWords() {
    console.log("login" + this.response.result[0].isskillkit);
    localStorage.setItem("username", this.response.result[0].user_name);
    localStorage.setItem("uname", this.response.result[0].name);
    localStorage.setItem("uid", this.response.result[0].id);
    localStorage.setItem("Branch_id_val", this.response.result[0].branch_id);
    localStorage.setItem("Section_id_val", this.response.result[0].section_id);

    /////////////////Theme & Lang////////////////
    this.entrytheme = this.response.result[0].selected_theme - 1;
    this.theme.changeTheme(this.theme.themenames[this.entrytheme]);
    localStorage.setItem("currenttheme", this.entrytheme.toString());
    localStorage.setItem("currentlang", this.response.result[0].selected_lang);
    localStorage.setItem("logincount", this.response.logincount);
    localStorage.setItem("isschedule", this.response.isschedule);
    localStorage.setItem("justlogged", "0");
    localStorage.setItem("year_status", this.response.result[0].current_year_status);
    localStorage.setItem("eid", "1");
    localStorage.setItem("playedtime", this.response.playedtime);
    localStorage.setItem("totaltime", this.response.result[0].total_time);
    localStorage.setItem("isskillkit", this.response.result[0].isskillkit);
    localStorage.setItem("loginpopupskillkit", "0");
    localStorage.setItem("loginpopupskillkit_date", "0");
    localStorage.setItem("loginpopupgames", "0");
    localStorage.setItem("loginpopupgames_date", "0");
    localStorage.setItem("timercount", "1");
    localStorage.setItem("assessment_check", this.response.assessment_check);
    localStorage.setItem("curr_assess_login", this.response.curr_assess);
    localStorage.setItem("event_label_status", "0");
    localStorage.setItem("isdark", this.response.result[0].select_base_theme);

    localStorage.setItem("logcheck", "1");
    localStorage.setItem("currentlangNo", this.response.result[0].selected_lang);
    localStorage.setItem("roadmap_chk", '0');
    localStorage.setItem("music_val", this.response.result[0].selected_music);
    localStorage.setItem("roadReturnStatus", (0).toString());
    localStorage.setItem("roadback", (0).toString());
    localStorage.setItem("ckp_initial_com", (0).toString());
    if (this.response.result[0].bgm == "ON") {
      localStorage.setItem("music_data_status", '1');
    }
    else {
      localStorage.setItem("music_data_status", '0');
    }

    localStorage.setItem("isfullschudle_stored", this.response.result[0].isfullschudle);
    localStorage.setItem("rep3_needleValue", '0');
    localStorage.setItem("rep4_needleValue", '0');
    localStorage.setItem("rep5_needleValue", '0');
    localStorage.setItem("dash_needleValue", '0');
    localStorage.setItem("pro_dob", this.response.result[0].dob);
    if (parseInt(localStorage.getItem("logincount")) == 1) {
      localStorage.setItem("timercount", "0");
    }
    this.lang_val = this.response.result[0].selected_lang;
    this.gen();
  }

}
