import { Component, Directive, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
@Directive({ selector: '[allowedRegExp]' })
export class HomeComponent implements OnInit {
  isDark: boolean;

  constructor() {
    this.isDark = parseInt(localStorage.getItem("isdark")) == 0 ? true : false;
  }


  ngOnInit() { }

}
