export class profileget {
    uid: string;
    timestamp: string;
    hashcode: string;
}

export class themeUpdate {
    uid: string;
    themename: string;
    timestamp: string;
    hashcode: string;
}

export class audioUpdate {
    uid: string;
    audioname: string;
    timestamp: string;
    hashcode: string;
}


export class langUpdate {
    uid: string;
    langid: number;
    timestamp: string;
    hashcode: string;
}

export class getThemeScore {
    uid: string;
    timestamp: string;
    hashcode: string;
}

export class profileimgData {
    newname: number;
    uid: string;
    timestamp: string;
    hashcode: string;
}
export class newpwd_SA_session {
    newpassword: string;
    uid: string;
    timestamp: string;
    hashcode: string;
}
export class updatebasetheme {
    uid: string;
    basetheme: string;
    timestamp: string;
    hashcode: string;
}
export class schChange {

    uid: string;
    schname: string;
    timestamp: string;
    hashcode: string;
}

export class nameChange {

    uid: string;
    name: string;
    timestamp: string;
    hashcode: string;
}