export class getreport {
    uid: string;
    eid: number;
    timestamp: string;
    hashcode: string;
}
export class getrank {
    uid: string;
    eid: number;
    branch_id: string;
    section_id: string;
    timestamp: string;
    hashcode: string;
}
export class getcert{
    uid: string;
    currentLevel: string;
    name: string;
}
export class getskill {
    uid: string;
    eid: number;
    timestamp: string;
    hashcode: string;
}
export class getcyclescore {
    uid: string;
    year_status: number;
    timestamp: string;
    hashcode: string;
}
export class getallassscore {
    uid: string;
    year_status: number;
    timestamp: string;
    hashcode: string;
}
export class getleaderscore {
    uid: string;
    section_id: string;
    timestamp: string;
    hashcode: string;
}
export class getSubSkillScore{
    uid: string;
    eid: number;
    timestamp: string;
    hashcode: string;
}


