export class scoreData {
    uid: string;
    eid: number;
    year_status: number;
    selDate: string;
    timestamp: string;
    hashcode: string;
}

export class dashData {
    uid: string;
    eid: number;
    section_id: string;
    branch_id: string;
    timestamp: string;
    hashcode: string;

}

export class assessData {
    uid: string;
    eid: number;
    timestamp: string;
    hashcode: string;
}

export class getisfullschudle {
    uid: string;
    year_status: number;
    timestamp: string;
    hashcode: string;
}

export class dashDatacrowny {
    uid: string;
    year_status: number;
    selDate: string;
    timestamp: string;
    hashcode: string;

}



