export class getassstar {
    uid: string;
    eid: number;
    year_status: number;
    timestamp: string;
    hashcode: string;
}



