export class url {
  serverurl: string = "http://localhost:7000/";
  // serverurl: string = "https://ts-ws.skillangels.com/";
  gameurl: string = "http://localhost/SkillAngelsGameNew/games1p.php";
  skillkiturl: string = "http://localhost/SkillAngelsGameNew/games1p.php";
  puzzleurl: string = "http://localhost:7000/menu";
  soundUrl: string = "assets/sound/";
  imageurl: string = "  https://ts-ws.skillangels.comresources/";
  galleryurl: string = "assets/images/Gallery/";
  gencertificateUrl: string = "http://localhost/certificateV1/certs.php";
  dlcertificateUrl: string =
    "https://hotsa.skillangels.com/certificateV1/createdCerts/";
  trailUrl: string = "http://localhost/b2v_v5_prelogin/index.php/trial/";
  homeUrl: string = "https://b2b.skillangels.com";
  regUrl: string = "https://b2b.skillangels.com/index.php/trial/registration";
  siteUrl: string = "https://hots.skillangels.com";

  // serverurl: string = "https://nodea.skillangels.com/";
  // gameurl: string = 'https://b2b.skillangels.com/SkillAngelsGameNew/games1p.php';
  // skillkiturl: string = 'https://b2b.skillangels.com/SkillAngelsGameNew/games1p.php';
  // puzzleurl: string = 'https://b2b.skillangels.com/menu';
  // soundUrl: string = "assets/sound/";
  // imageurl: string = "https://nodea.skillangels.com/resources/";
  // galleryurl: string = "assets/images/Gallery/";
  // gencertificateUrl: string = "https://b2b.skillangels.com/certificateV1/certs.php";
  // dlcertificateUrl: string = "https://b2b.skillangels.com/certificateV1/createdCerts/";
  // trailUrl: string = "https://b2b.skillangels.com/index.php/trial/";
  // homeUrl:string="https://b2b.skillangels.com";
  // regUrl:string="https://b2b.skillangels.com/index.php/trial/registration";

  // serverurl: string = "https://btc-ws.skillangels.com/";
  // gameurl: string = 'https://b2b.skillangels.com/SkillAngelsGameNew/games1p.php';
  // skillkiturl: string = 'https://b2b.skillangels.com/SkillAngelsGameNew/games1p.php';
  // puzzleurl: string = 'https://b2b.skillangels.com/menu';
  // soundUrl: string = "assets/sound/";
  // imageurl: string = "https://btc-ws.skillangels.com/resources/";
  // galleryurl: string = "assets/images/Gallery/";
  // gencertificateUrl: string = "https://b2b.skillangels.com/certificateV1/certs.php";
  // dlcertificateUrl: string = "https://b2b.skillangels.com/certificateV1/createdCerts/";
  // trailUrl: string = "https://b2b.skillangels.com/index.php/trial/";
  // homeUrl:string="https://b2b.skillangels.com";
  // regUrl:string="https://b2b.skillangels.com/index.php/trial/registration";

  // serverurl: string = "https://btc-ws.skillangels.com/";
  // gameurl: string = 'https://b2b.skillangels.com/SkillAngelsGameNew/games1p.php';
  // skillkiturl: string = 'https://b2b.skillangels.com/SkillAngelsGameNew/games1p.php';
  // puzzleurl: string = 'https://b2b.skillangels.com/menu';
  // soundUrl: string = "assets/sound/";
  // imageurl: string = "https://btc-ws.skillangels.com/resources/";
  // galleryurl: string = "assets/images/Gallery/";
  // gencertificateUrl: string = "https://b2b.skillangels.com/certificateV1/certs.php";
  // dlcertificateUrl: string = "https://b2b.skillangels.com/certificateV1/createdCerts/";
  // trailUrl: string = "https://b2b.skillangels.com/index.php/trial/";
  // homeUrl:string="https://b2b.skillangels.com";
  // regUrl:string="https://b2b.skillangels.com/index.php/trial/registration";

  // serverurl: string = "https://devschool-ws.skillangels.com/";
  // gameurl: string = 'https://devschool-ws.skillangels.com/game';
  // skillkiturl: string = 'https://devschool-ws.skillangels.com/game';
  // puzzleurl: string = 'https://devschool-ws.skillangels/menu';
  // soundUrl: string = "assets/sound/";
  // imageurl: string = "https://devschool-ws.skillangels.com/resources/";
  // galleryurl: string = "assets/images/Gallery/";
  // gencertificateUrl: string = "https://devschool-ws.skillangels/certificateV1/certs.php";
  // dlcertificateUrl: string = "https://devschool-ws.skillangels/certificateV1/createdCerts/";
  // trailUrl: string = "https://devschool-ws.skillangels/index.php/trial/";
  // homeUrl:string="https://devschool-ws.skillangels.com";
  // regUrl:string="https://devschool-ws.skillangels/index.php/trial/registration";

  // serverurl: string = "http://localhost:7000/";
  // imageurl: string = "https://localhost:7000/resources/";

  // serverurl: string = "https://prodschool-ws.skillangels.com/";
  // gameurl: string = 'https://prodschool-ws.skillangels.com/game';
  // skillkiturl: string = 'https://prodschool-ws.skillangels.com/game';
  // puzzleurl: string = 'https://prodschool-ws.skillangels.com/menu';
  // soundUrl: string = "assets/sound/";
  // imageurl: string = "https://prodschool-ws.skillangels.com/resources/";
  // galleryurl: string = "assets/images/Gallery/";
  // gencertificateUrl: string = "https://prodschool-ws.skillangels.com/certificateV1/certs.php";
  // dlcertificateUrl: string = "https://prodschool-ws.skillangels.com/certificateV1/createdCerts/";
  // trailUrl: string = "https://prodschool-ws.skillangels.com/index.php/trial/";
  // homeUrl:string="https://prodschool-ws.skillangels.com";
  // regUrl:string="https://prodschool-ws.skillangels.com/index.php/trial/registration";
  //  siteUrl:string="https://hots.skillangels.com"

  // serverurl: string = "https://prodschool-ws.skillangels.com/";
  // gameurl: string = 'https://prodschool-ws.skillangels.com/game';
  // skillkiturl: string = 'https://prodschool-ws.skillangels.com/game';
  // puzzleurl: string = 'https://prodschool-ws.skillangels.com/menu';
  // soundUrl: string = "assets/sound/";
  // imageurl: string = "https://prodschool-ws.skillangels.com/resources/";
  // galleryurl: string = "assets/images/Gallery/";
  // gencertificateUrl: string = "https://prodschool-ws.skillangels.com/certificateV1/certs.php";
  // dlcertificateUrl: string = "https://prodschool-ws.skillangels.com/certificateV1/createdCerts/";
  // trailUrl: string = "https://prodschool-ws.skillangels.com/index.php/trial/";
  // homeUrl:string="https://prodschool-ws.skillangels.com";
  // regUrl:string="https://prodschool-ws.skillangels.com/index.php/trial/registration";
  //  siteUrl:string="https://hots.skillangels.com"

  //     serverurl: string = "https://devschool-ws.skillangels.com/";
  // gameurl: string = 'https://devschool-ws.skillangels.com/game';
  // skillkiturl: string = 'https://devschool-ws.skillangels.com/game';
  // puzzleurl: string = 'https://devschool-ws.skillangels/menu';
  // soundUrl: string = "assets/sound/";
  // imageurl: string = "https://devschool-ws.skillangels.com/resources/";
  // galleryurl: string = "assets/images/Gallery/";
  // gencertificateUrl: string = "https://devschool-ws.skillangels/certificateV1/certs.php";
  // dlcertificateUrl: string = "https://devschool-ws.skillangels/certificateV1/createdCerts/";
  // trailUrl: string = "https://devschool-ws.skillangels/index.php/trial/";
  // homeUrl:string="https://devschool-ws.skillangels.com";
  // regUrl:string="https://devschool-ws.skillangels/index.php/trial/registration";
  // siteUrl:string="https://devschool.skillangels.com"

  // serverurl: string = "https://stagschool-ws.skillangels.com/";
  // gameurl: string = 'https://stagschool-ws.skillangels.com/game';
  // skillkiturl: string = 'https://stagschool-ws.skillangels.com/game';
  // puzzleurl: string = 'https://stagschool-ws.skillangels.com/menu';
  // soundUrl: string = "assets/sound/";
  // imageurl: string = "https://stagschool-ws.skillangels.com/resources/";
  // galleryurl: string = "assets/images/Gallery/";
  // gencertificateUrl: string = "https://stagschool-ws.skillangels.com/certificateV1/certs.php";
  // dlcertificateUrl: string = "https://stagschool-ws.skillangels.com/certificateV1/createdCerts/";
  // trailUrl: string = "https://stagschool-ws.skillangels.com/index.php/trial/";
  // homeUrl:string="https://stagschool-ws.skillangels.com";
  // regUrl:string="https://stagschool-ws.skillangels.com/index.php/trial/registration";
  // siteUrl:string="https://stagschool.skillangels.com"
}
