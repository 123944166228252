import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as sha1 from 'js-sha1';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class IpService {
  enablePopup=new BehaviorSubject(false)
  profilePopup$=this.enablePopup.asObservable()
  constructor(private http: HttpClient, private toastr: ToastrService) { }
  public getIPAddress() {
    return this.http.get("https://api.ipify.org/?format=json");
  }
  public getUser() {
    return this.http.get(`http://www.geoplugin.net/json.gp?ip=` + localStorage.getItem("my_ip"));
  }
  public gethash(date_val) {
    // console.log(localStorage.getItem("session_id"))
    // console.log(date_val)
    let msgtohash = localStorage.getItem("session_id") + 'B2C@Ed$6' + date_val;
    let hash = sha1(msgtohash)
    return hash;
  }

  enableToaster(status, content) {
    if (status == 's') { this.toastr.success('Success', content); }
    if (status == 'e') { this.toastr.error('Error', content); }
    if (status == 'w') { this.toastr.warning('Warning', content); }
}

getPopup(quantity:any){
  this.enablePopup.next(quantity)
}

}
